import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import { BREADCRUMB_CATEGORIES } from '../constants/breadcrumb.constant';

const BreadcrumbsContext = createContext(null);

const breadcrumbNames = [
  {
    category: BREADCRUMB_CATEGORIES.API_KEY,
    type: 'API_KEY_PARENT',
    pattern: /^\/api-keys/g,
    path: '/api-keys',
    name: 'API Key',
    order: 1,
  },
  {
    parent: 'API_KEY_PARENT',
    type: 'API_KEY_CHILD_1',
    pattern: /^\/api-keys\/.+$/g,
    name: 'API Key detail',
    order: 2,
  },
  {
    parent: 'API_KEY_PARENT',
    type: 'API_KEY_CHILD_1',
    pattern: /^\/api-keys\/create$/g,
    name: 'Create',
    order: 3,
  },
  {
    type: 'MACHINE_PARENT',
    pattern: /^\/nodes/g,
    path: '/nodes',
    name: 'Nodes',
    order: 1,
  },
  {
    category: BREADCRUMB_CATEGORIES.MACHINE,
    type: 'MACHINE_PARENT',
    pattern: /^\/$/g,
    path: '/',
    name: 'Nodes',
    order: 1,
  },
  {
    parent: 'MACHINE_PARENT',
    type: 'MACHINE_CHILD_1',
    pattern: /^\/nodes\/.+$/g,
    name: 'Node detail',
    order: 2,
  },
  {
    category: BREADCRUMB_CATEGORIES.PERMISSION,
    type: 'PERMISSION_PARENT',
    pattern: /^\/permissions/g,
    path: '/permissions',
    name: 'Permissions',
    order: 1,
  },
  {
    parent: 'PERMISSION_PARENT',
    type: 'PERMISSION_CHILD_1',
    pattern: /\/permissions\/.+$/g,
    name: 'Permission detail',
    order: 2,
  },
  {
    category: BREADCRUMB_CATEGORIES.MODEL_VERSION_CONTROL,
    type: 'MODE_VERSION_PARENT',
    pattern: /^\/model-version-controls/g,
    path: '/model-version-controls',
    name: 'Model versions',
    order: 1,
  },
  {
    parent: 'MODE_VERSION_PARENT',
    type: 'MODE_VERSION_CHILD_1',
    pattern: /\/model-version-controls\/.+$/g,
    name: 'Model version detail',
    order: 2,
  },
  {
    parent: 'MODE_VERSION_PARENT',
    type: 'MODE_VERSION_CHILD_2',
    pattern: /\/model-version-controls\/create$/g,
    name: 'Create',
    order: 3,
  },
  {
    category: BREADCRUMB_CATEGORIES.PLAYGROUND,
    type: 'MODE_VERSION_PARENT',
    pattern: /^\/playground/g,
    path: '/playground',
    name: 'Playground',
    order: 1,
  },
  {
    category: BREADCRUMB_CATEGORIES.SETTING,
    type: 'SETTING_PARENT',
    pattern: /^\/settings/g,
    path: '/settings',
    name: 'General setting',
    order: 1,
  },
  {
    category: BREADCRUMB_CATEGORIES.USER_SETTING,
    type: 'SETTING_CHILD_1',
    pattern: /^\/settings\/users$/g,
    path: '/settings/users',
    name: 'User setting',
    order: 2,
  },
  {
    category: BREADCRUMB_CATEGORIES.PERMISSION_SETTING,
    type: 'SETTING_CHILD_2',
    pattern: /^\/settings\/permissions$/g,
    path: '/settings/permissions',
    name: 'Permission setting',
    order: 3,
  },
  {
    category: BREADCRUMB_CATEGORIES.DOWNLOAD_FILE,
    type: 'SETTING_PARENT',
    pattern: /^\/download-files/g,
    path: '/download-files',
    name: 'Download joining file',
    order: 1,
  },
  {
    category: BREADCRUMB_CATEGORIES.CHART,
    type: 'CHART',
    pattern: /^\/dashboards$/g,
    path: '/dashboards',
    name: 'Dashboards',
    order: 1,
  },
];

export const BreadcrumbsProvider = ({ children }) => {
  const [breadcrumbs, setBreadCrumbs] = useState([]);
  const location = useLocation();

  const findBreadcrumbsByRoute = useCallback(
    (route) =>
      breadcrumbNames.reduce(
        (init, item) =>
          route.match(item.pattern)
            ? init.concat({
                order: item.order,
                name: item.name,
                path: route.match(item.pattern)[0],
              })
            : init,
        [],
      ),
    [],
  );

  const changeSidebarCategory = useCallback(
    (category) => () => {
      const breadcrumbs = breadcrumbNames
        .filter((item) => item.category === category)
        .map((item) => ({
          order: item.order,
          name: item.name,
          path: item.path,
        }));
      console.log(breadcrumbs);
      setBreadCrumbs(breadcrumbs);
    },
    [],
  );

  // Init current location breadcrumbs
  /**
   * breadcrumbs: {
   *    order: 1,
   *    key: /api-keys
   *    name: Api key
   *    path: /api-keys
   * }
   */
  useEffect(() => {
    const breadcrumbs = findBreadcrumbsByRoute(location.pathname);
    breadcrumbs.sort((a, b) => a.order - b.order);
    setBreadCrumbs(breadcrumbs);
  }, []);

  useEffect(() => {
    const breadcrumbs = findBreadcrumbsByRoute(location.pathname);
    breadcrumbs.sort((a, b) => a.order - b.order);
    setBreadCrumbs(breadcrumbs);
  }, [location.pathname]);

  const value = useMemo(
    () => ({ breadcrumbs, changeSidebarCategory }),
    [breadcrumbs],
  );

  return (
    <BreadcrumbsContext.Provider value={value}>
      {children}
    </BreadcrumbsContext.Provider>
  );
};

export const useBreadcrumbs = () => useContext(BreadcrumbsContext);

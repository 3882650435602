import React, { useCallback, useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import { XMarkIcon, ArrowPathIcon } from '@heroicons/react/24/solid';
import clsx from 'clsx';

import {
  createPermission,
  updatePermissionByIdentifier,
} from '../../handlers/apiCallHandler.tsx';
import { useAuth } from '../../handlers/authHandler';
import { useToast } from '../../contexts/ToastContext';

const PermissionForm = ({
  permission = {
    id: '',
    title: '',
    description: '',
    permission: '',
    created_at: dayjs().utc().format('YYYY-MM-DDTHH:mm:ss'),
  },
  close,
  appendPermission,
}) => {
  const idRef = useRef(permission.id);
  const titleRef = useRef(null);
  const descriptionRef = useRef(null);
  const permissionRef = useRef(null);

  const [loading, setLoading] = useState(false);

  const auth = useAuth();

  const { openToast } = useToast();

  useEffect(() => {
    titleRef.current.value = permission.title || '';
    descriptionRef.current.value = permission.description || '';
    permissionRef.current.value = permission.permission || '';
    idRef.current = permission.id;
  }, [permission]);

  const handleSubmit = useCallback(() => {
    setLoading(true);

    const body = {
      title: titleRef.current.value,
      description: descriptionRef.current.value,
      permission: permissionRef.current.value,
    };

    if (Object.keys(permission).length) {
      updatePermissionByIdentifier(idRef.current, body)
        .then(() => {
          setTimeout(() => {
            close();
            setLoading(false);
          }, 1000);
        })
        .catch((err) => {
          setLoading(false);
          if (err?.response?.status === 401) return auth.logout();

          const messsage = err?.response?.data?.message || err?.messsage;

          openToast('ERROR', messsage);
        });

      return;
    }

    createPermission(body)
      .then((data) => {
        console.log(data);
        setTimeout(() => {
          close();
          appendPermission(data);
          setLoading(false);
        }, 1000);
      })
      .catch((err) => {
        setLoading(false);
        if (err?.response?.status === 401) return auth.logout();

        const messsage = err?.response?.data?.message || err?.messsage;

        openToast('ERROR', messsage);
      });
  }, [permission]);

  return (
    <div className="bg-white p-4 rounded-md shadow-sm grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-3 w-3/4 relative">
      <div
        onClick={close}
        className="cursor-pointer absolute top-1 right-1 ms-auto bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8">
        <XMarkIcon className="size-4 text-slate-500" />
      </div>
      <div>
        <label
          htmlFor="title"
          className="block mb-2 text-sm font-medium text-gray-900">
          Title
        </label>
        <input
          ref={titleRef}
          id="title"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-orange-600 focus:border-orange-600 block w-full p-2.5"
          placeholder="Enter title"
          required
        />
      </div>
      <div>
        <label
          htmlFor="description"
          className="block mb-2 text-sm font-medium text-gray-900">
          Description
        </label>
        <input
          ref={descriptionRef}
          id="description"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-orange-600 focus:border-orange-600 block w-full p-2.5"
          placeholder="Enter description"
          required
        />
      </div>
      <div>
        <label
          htmlFor="permission"
          className="block mb-2 text-sm font-medium text-gray-900">
          Permission
        </label>
        <input
          ref={permissionRef}
          id="permission"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-orange-600 focus:border-orange-600 block w-full p-2.5"
          placeholder="Enter permission"
          required
        />
      </div>
      <div className="md:block"></div>
      <div
        onClick={handleSubmit}
        className="p-2 bg-orange-600 text-center text-white rounded-md font-medium hover:bg-orange-700 cursor-pointer">
        {Object.keys(permission).length ? (
          <div className="flex items-center justify-center">
            {loading && <ArrowPathIcon className="size-4 animate-spin" />}
            <div className={clsx([loading && 'ml-2'])}>Save</div>
          </div>
        ) : (
          <div className="flex items-center justify-center">
            {loading && <ArrowPathIcon className="size-4 animate-spin" />}
            <div className={clsx([loading && 'ml-2'])}>Create</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PermissionForm;

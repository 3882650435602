import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import NodeState from '../../components/NodeState';
import {
  deleteNodeByIdentifier,
  getNodeStates,
} from '../../handlers/apiCallHandler.tsx';
import { useAuth } from '../../handlers/authHandler';
import Breadcrumbs from '../../components/Breadcrumbs';
import { TrashIcon } from '@heroicons/react/24/solid';
import NodeListSkeleton from './skeleton';
// import { useWS } from '../../contexts/WebSocketContext';

const NodeList = () => {
  const [nodes, setNodes] = useState([]);
  const [loading, setLoading] = useState(false);

  const auth = useAuth();

  // const { wsSubjectRef } = useWS();

  useEffect(() => {
    setLoading(true);

    getNodeStates()
      .then((data) => {
        setNodes(data);
        setLoading(false);
      })
      .catch(() => {
        auth.logout();
        return;
      });
  }, []);

  const removeNode = useCallback(
    (nodeId) => () => {
      setNodes((nodes) => nodes.filter((node) => node.node_id !== nodeId));

      deleteNodeByIdentifier(nodeId).catch((err) => {
        if (err?.response?.status === 401) return auth.logout();
      });
    },
    [],
  );

  useEffect(() => {
    document.title = 'Digime workers dashboard';
  }, []);

  return (
    <div className="relative">
      <Breadcrumbs />
      <NodeListSkeleton enabled={loading} />
      <div className="mt-3 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
        {nodes.map((node, index) => (
          <div className="relative">
            <div
              onClick={removeNode(node.node_id)}
              className="absolute top-0 right-0 p-2 bg-red-600 text-white rounded-bl-md rounded-tr-md cursor-pointer hover:shadow-md">
              <TrashIcon className="size-4" />
            </div>
            <Link key={index} to={`/nodes/${node.node_id}`}>
              <NodeState node={node} />
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NodeList;

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { marked } from 'marked';

import Breadcrumbs from '../../components/Breadcrumbs';
import MultipleSelect from '../../common/MultipleSelect';
import { GPUProvider, OperatingSystem } from '../../types/common';
import { getInstallationGuide } from '../../handlers/apiCallHandler';
import { useAuth } from '../../handlers/authHandler';
import { OptionType, OSGuideRef } from '../../types/reactRef';
import clsx from 'clsx';

const ubuntuVersions: OptionType[] | any = [
  {
    id: '20.04',
    title: '20.04',
    value: '20.04',
  },
  {
    id: '22.04',
    title: '22.04',
    value: '22.04',
  },
  {
    id: '24.04',
    title: '24.04',
    value: '24.04',
  },
];

const windowVersions: OptionType[] | any = [
  {
    id: '22H2',
    title: '22H2 to now',
    value: '22H2',
  },
];

const DownloadFile = () => {
  const [OSGuide, setOSGuide] = useState<{
    os: string;
    gpu: string;
    data: string;
  }>({ os: '', gpu: '', data: '' });
  const osGuideRef: React.RefObject<OSGuideRef> = useRef({});
  const osVersionRef: React.RefObject<any> = useRef([]);

  const auth: any = useAuth();

  useEffect(() => {
    getInstallationGuide(OperatingSystem.WINDOWS)
      .then((data) => {
        osGuideRef.current[OperatingSystem.WINDOWS] = data.data;
        return getInstallationGuide(OperatingSystem.UBUNTU);
      })
      .then((data) => {
        osGuideRef.current[OperatingSystem.UBUNTU] = data.data;
      })
      .catch((err) => {
        if (err?.response?.status === 401) return auth.logout();
      });
  }, []);

  const selectOS = useCallback(
    (os: OperatingSystem) => () => {
      osVersionRef.current = [];
      setOSGuide((state) => ({ ...state, os, data: osGuideRef.current[os] }));
    },
    [],
  );

  const selectGPU = useCallback(
    (gpuProvider: GPUProvider) => () => {
      setOSGuide((state) => ({ ...state, gpu: gpuProvider }));
    },
    [],
  );

  const selectVersion = useCallback((value: OptionType) => {
    osVersionRef.current = [value];
  }, []);

  const deselectVersion = useCallback(() => {
    osVersionRef.current = [];
  }, []);

  return (
    <div>
      <Breadcrumbs />
      <div className="mt-3 grid grid-cols-1 sm:grid-cols-2 gap-2">
        <div>
          <div className="text-md text-slate-700 font-medium">
            Operating System
          </div>
          <div className="flex items-center w-full mt-3">
            <div
              onClick={selectOS(OperatingSystem.UBUNTU)}
              className={clsx([
                'p-3 bg-white rounded-md w-full shadow text-center cursor-pointer me-2.5 hover:bg-gray-50',
                OSGuide.os === OperatingSystem.UBUNTU &&
                  'border border-orange-600',
              ])}>
              Ubuntu
            </div>
            <div
              onClick={selectOS(OperatingSystem.WINDOWS)}
              className={clsx([
                'p-3 bg-white rounded-md w-full shadow text-center cursor-pointer me-2.5 hover:bg-gray-50',
                OSGuide.os === OperatingSystem.WINDOWS &&
                  'border border-orange-600',
              ])}>
              Windows
            </div>
          </div>
          <div className="text-md text-slate-700 mt-5 font-medium mb-3">
            Version
          </div>
          <MultipleSelect
            text="Select version"
            isMutileSelect={false}
            list={
              OSGuide.os === OperatingSystem.UBUNTU
                ? ubuntuVersions
                : windowVersions
            }
            selected={osVersionRef.current}
            onSelect={selectVersion}
            onDeselect={deselectVersion}
          />
          <div className="text-md text-slate-700 mt-5 font-medium mb-3">
            Graphics Processing Unit (GPU)
          </div>
          <div className="flex items-center w-full mt-3">
            <div
              onClick={selectGPU(GPUProvider.NVIDIA)}
              className={clsx([
                "p-3 bg-white rounded-md w-full shadow text-center cursor-pointer me-2.5 hover:bg-gray-50",
                OSGuide.gpu === GPUProvider.NVIDIA && 'border border-orange-600'
              ])}>
              NVIDIA
            </div>
            <div
              onClick={selectGPU(GPUProvider.AMD)}
              className={clsx([
                "p-3 bg-white rounded-md w-full shadow text-center cursor-pointer me-2.5 hover:bg-gray-50",
                OSGuide.gpu === GPUProvider.AMD && 'border border-orange-600'
              ])}>
              AMD
            </div>
          </div>
          <div className="mt-5 p-3 text-center bg-orange-600 rounded-md text-white font-medium shadow cursor-pointer hover:bg-orange-700">
            Get link
          </div>
        </div>
        <div className="h-full">
          <div className="text-md text-slate-700 font-medium">
            Documentation
          </div>
          <div className="bg-white p-3 rounded-md">
            <div
              className="h-[32rem] overflow-y-scroll"
              dangerouslySetInnerHTML={{
                __html: marked(OSGuide.data),
              }}></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DownloadFile;

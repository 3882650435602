import React, { useCallback, useState } from 'react';
import CodeMirror from '@uiw/react-codemirror';
import { yaml, yamlLanguage } from '@codemirror/lang-yaml';
import { dracula } from '@uiw/codemirror-theme-dracula';
import {
  XMarkIcon,
  Bars3CenterLeftIcon,
  ArrowPathIcon,
} from '@heroicons/react/24/solid';
import { Transition } from '@headlessui/react';
import clsx from 'clsx';

import { useDeployment } from '../../contexts/DeploymentContext';
import { deployContainerByYamlFile } from '../../handlers/apiCallHandler.tsx';
import { useAuth } from '../../handlers/authHandler';
import { autocompletionHandler } from '../../handlers/autocompletionHandler';

const defaultDeploymentDefinition = `# node_id: "9b628bd3-760e-4d12-b542-b1d5f590cc86"
image: registry.digime.asia/ml-backend:od-poi-pa
container_name: ml-backend-od-poi-pa
container_label:
  app: ml-od-poi-pa
  # prediciton_type will be in prediction type in model management
  prediction_type: OBJECT_DETECTION_PREDICTION
  # model version is used to query properly container to sending prediction job
  ml_model_version: od-poi-pa
  issuer: digime
# command: ["rq", "worker", "--with-scheduler"]
environments:
  - STORAGE_MODE=public
restart: unless-stopped
# For AMD
# devices:
#  - "/dev/kfd"
#  - "/dev/dri"
#security_opt
#  - seccomp:unconfined
ports:
  - 9090:9090
volumes:
  - "/mnt/weight/od-poi-pa:/src/weights"
networks:
  - label-studio-network
deploy:
  mode: spread
  replicas: 1
  resources:
    limits:
      cpus: 150
      memory: 1500M
    reservations:
      cpus: 150
      memory: 1500M
      # For NVIDIA
      #devices:
      #  - driver: nvidia
      #    count: 1
      #    device_ids: ["0", "1"]
      #    capabilities: [gpu]
          
          `;

const yamlCompletion = yamlLanguage.data.of({
  autocomplete: (context) => {
    let word = context.matchBefore(/@node*$/);

    if (!word) return;

    if (word.from == word.to && !context.explicit) return null;

    return {
      from: word.from,
      options: autocompletionHandler.getNodeCodeMirrorOptions(),
    };
  },
});

const imageNameCompletion = yamlLanguage.data.of({
  autocomplete: (context) => {
    let word = context.matchBefore(/@image*$/);

    if (!word) return;

    if (word.from == word.to && !context.explicit) return null;

    return {
      from: word.from,
      options: autocompletionHandler.getImageVersionCodeMirrorOptions(),
    };
  },
});

const DeploymentEditor = () => {
  const { openYamlEditor, handleCloseYamlEditor } = useDeployment();

  const [yamlCode, setYamlCode] = useState(defaultDeploymentDefinition);
  const [isDeployed, setIsDeployed] = useState(false);

  const auth = useAuth();

  const changeYamlCode = useCallback((val) => {
    setYamlCode(val);
  }, []);

  const deployContainer = useCallback(() => {
    if (isDeployed) return;

    setIsDeployed(true);

    let deployContainerTimeoutId = null;
    deployContainerByYamlFile(yamlCode)
      .then(() => {
        deployContainerTimeoutId = setTimeout(() => {
          setIsDeployed(false);
          handleCloseYamlEditor();
        }, 2000);
      })
      .catch((error) => {
        deployContainerTimeoutId = setTimeout(() => setIsDeployed(false), 2000);
        if (error.response.status === 401) {
          return auth.logout();
        }
      });

    return () => clearTimeout(deployContainerTimeoutId);
  }, [yamlCode, isDeployed]);

  const disableBodyScrollbar = useCallback(() => {
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
  }, []);

  const enableBodyScrollbar = useCallback(() => {
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
  }, []);

  return (
    <Transition
      show={openYamlEditor}
      beforeEnter={disableBodyScrollbar}
      afterLeave={enableBodyScrollbar}>
      <div
        className={clsx([
          // Base styles
          'absolute top-0 left-0 z-20 transition ease-in-out h-3/4 overflow-y-auto w-full h-full',
          // Shared closed styles
          'data-[closed]:opacity-0',
          // Entering styles
          'data-[enter]:duration-200',
          // Leaving styles
          'data-[leave]:duration-300',
        ])}>
        <div className="absolute top-0 left-0 w-full z-30 flex items-center justify-center h-full">
          <div className="p-4 bg-white rounded-md w-full h-full sm:w-3/5 sm:h-auto">
            <div className="mb-3 text-slate-600 flex items-center justify-between">
              <div className="flex items-center">
                <Bars3CenterLeftIcon className="size-5" />
                <div className="ml-2 font-medium">Define deployment</div>
              </div>
              <button
                onClick={handleCloseYamlEditor}
                className="ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8">
                <XMarkIcon className="size-4" />
              </button>
            </div>
            <CodeMirror
              value={yamlCode}
              onChange={changeYamlCode}
              className="h-[32rem] overflow-y-scroll"
              theme={dracula}
              extensions={[yaml(), yamlCompletion, imageNameCompletion]}
            />
            <div className="mt-2 flex justify-end">
              <div
                onClick={deployContainer}
                className={clsx([
                  'bg-orange-600 hover:bg-orange-700 cursor-pointer w-full sm:w-24 text-white rounded-md p-2 text-sm text-center flex items-center justify-center',
                  isDeployed && 'cursor-not-allowed',
                ])}>
                {isDeployed && (
                  <ArrowPathIcon className="size-4 animate-spin" />
                )}
                <div className="ml-1">
                  {isDeployed ? 'Deploying' : 'Submit'}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-slate-500 opacity-50 w-full h-full z-20 absolute top-0 left-0"></div>
      </div>
    </Transition>
  );
};

export default DeploymentEditor;

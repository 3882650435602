import React, { useCallback, useEffect, useRef } from 'react';

import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from '@headlessui/react';
import Badge from '../../common/Badge';
import MultipleSelect from '../../common/MultipleSelect';

const authTypes = [
  {
    id: 'HEADER',
    title: 'HEADER',
    value: 'header',
  },
  {
    id: 'QUERY',
    title: 'QUERY',
    value: 'query',
  },
  {
    id: 'BODY',
    title: 'BODY',
    value: 'body',
  },
];

const WebhookConfig = ({ webhookRef }) => {
  const selectedAuthTypeRef = useRef([]);

  const webhookUrlRef = useRef(null);
  const webhookAuthKeyRef = useRef(null);
  const webhookAuthValueRef = useRef(null);

  const handleEnterUrl = useCallback((event) => {
    webhookRef.current = {
      ...webhookRef.current,
      webhook: event.target.value,
    };
  }, []);

  const handleEnterAuthKey = useCallback((event) => {
    webhookRef.current = {
      ...webhookRef.current,
      auth_parameter: event.target.value,
    };
  }, []);

  const handleEnterAuthValue = useCallback((event) => {
    webhookRef.current = {
      ...webhookRef.current,
      secret: event.target.value,
    };
  }, []);

  const onSelectAuthType = useCallback((value) => {
    selectedAuthTypeRef.current = [value];
    webhookRef.current = {
      ...webhookRef.current,
      auth_type: value['value'],
    };
  }, []);

  const onDeselectAuthType = useCallback(() => {
    selectedAuthTypeRef.current = [];
    webhookRef.current = {
      ...webhookRef.current,
      auth_type: null,
    };
  }, []);

  useEffect(() => {
    webhookUrlRef.current.value = webhookRef.current?.webhook || '';
    webhookAuthKeyRef.current.value = webhookRef.current?.auth_parameter || '';
    webhookAuthValueRef.current.value = webhookRef.current?.secret || '';
    selectedAuthTypeRef.current = [
      {
        id: webhookRef.current?.auth_type?.toUpperCase(),
        title: webhookRef.current?.auth_type?.toUpperCase(),
        value: webhookRef.current?.auth_type,
      },
    ];
  }, [webhookRef.current]);

  return (
    <Disclosure>
      <div className="flex items-center w-full">
        <DisclosureButton className="text-md font-medium text-gray-900">
          <Badge text="Webhook" customClassName="text-sm md:text-md" />
        </DisclosureButton>
        <div className="border-t w-full ms-1.5 hover:bg-slate-400"></div>
      </div>
      <DisclosurePanel
        unmount={false}
        transition
        className="origin-top transition duration-200 ease-out data-[closed]:-translate-y-6 data-[closed]:opacity-0">
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-2">
          <div>
            <label
              htmlFor="url"
              className="block mb-2 text-sm font-medium text-gray-900">
              Url
            </label>
            <input
              id="url"
              ref={webhookUrlRef}
              onChange={handleEnterUrl}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-orange-600 focus:border-orange-600 block w-full p-2.5"
              placeholder="Example: https://www.webhook.com"
              required
            />
          </div>
          <div>
            <label className="block mb-2 text-sm font-medium text-gray-900">
              Authentication type
            </label>
            <MultipleSelect
              list={authTypes}
              selected={selectedAuthTypeRef.current}
              onSelect={onSelectAuthType}
              onDeselect={onDeselectAuthType}
              text="Select authentication type"
              isMutileSelect={false}
            />
          </div>
          <div>
            <label
              htmlFor="authentication-key"
              className="block mb-2 text-sm font-medium text-gray-900">
              Authentication key
            </label>
            <input
              ref={webhookAuthKeyRef}
              onChange={handleEnterAuthKey}
              id="authentication-key"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-orange-600 focus:border-orange-600 block w-full p-2.5"
              placeholder="Example: X-Api-Key"
              required
            />
          </div>
          <div>
            <label
              htmlFor="authentication-value"
              className="block mb-2 text-sm font-medium text-gray-900">
              Authentication value
            </label>
            <input
              ref={webhookAuthValueRef}
              onChange={handleEnterAuthValue}
              id="authentication-value"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-orange-600 focus:border-orange-600 block w-full p-2.5"
              placeholder="Api key"
              required
            />
          </div>
        </div>
      </DisclosurePanel>
    </Disclosure>
  );
};

export default WebhookConfig;

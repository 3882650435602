import React, { useCallback, useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';
import { Bars4Icon, XMarkIcon } from '@heroicons/react/24/solid';
import CodeMirror from '@uiw/react-codemirror';
import { StreamLanguage } from '@codemirror/language';
import { textile } from '@codemirror/legacy-modes/mode/textile';
import { dracula } from '@uiw/codemirror-theme-dracula';
import { EditorView } from '@uiw/react-codemirror';

import { useDeployment } from '../../contexts/DeploymentContext';
import { wsConfig } from '../../handlers/websocketHandler';

const ContainerLog = () => {
  const [log, setLog] = useState('No log');
  const { nodeId } = useParams();

  const { containerId, handleContainerId } = useDeployment();

  const closeContainerLogPopup = useCallback(() => {
    handleContainerId(null, undefined);
  }, []);

  const updateLog = useCallback((data) => {
    if (!data?.reason) return;

    if (data.type != 'CONTAINER_LOG') return;

    setLog(data.reason);
  }, []);

  useEffect(() => {
    if (containerId.error_event) {
      setLog(containerId.error_event.error);
      return;
    }

    const gettingLogMessage = {
      data_cmd: {
        type: 'CONTAINER_LOG',
        payload: {
          node_id: nodeId,
          container_id: containerId.container_id,
        },
      },
    };

    wsConfig.sendMessage(gettingLogMessage);

    return () => {
      const cancelGettingLogMessage = {
        data_cmd: {
          type: 'CANCEL',
          payload: {
            command_service: 'CONTAINER_LOG',
            container_id: containerId.container_id,
          },
        },
      };

      wsConfig.sendMessage(cancelGettingLogMessage);
    };
  }, []);

  useEffect(() => {
    const subscription = wsConfig.getContainerLogSubj().subscribe({
      next: updateLog,
    });

    return () => subscription?.unsubscribe();
  }, []);

  useEffect(() => {
    const closeLogByEscKey = (event) => {
      if (event.keyCode !== 27) return;

      handleContainerId(null);
    };

    document.addEventListener('keydown', closeLogByEscKey);

    return () => document.removeEventListener('keydown', closeLogByEscKey);
  }, []);

  return (
    <div className="flex items-center justify-center h-lvh sm:h-full">
      <div className="bg-white p-4 w-full h-full sm:w-3/5 sm:h-3/5 rounded-md">
        <div className="flex items-center justify-between mb-3">
          <div className="text-slate-600 flex items-center">
            <Bars4Icon className="size-5" />
            <div className="text-md ml-2 font-medium">
              Container log {containerId?.container_id?.slice(0, 10)}
            </div>
          </div>
          <button
            onClick={closeContainerLogPopup}
            className="ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8">
            <XMarkIcon className="size-4 text-slate-600 cursor-pointer" />
          </button>
        </div>
        <CodeMirror
          value={log}
          theme={dracula}
          className="h-[28rem] overflow-y-scroll"
          readOnly
          extensions={[StreamLanguage.define(textile), EditorView.lineWrapping]}
          options={{
            lineWrapping: true,
          }}
        />
      </div>
    </div>
  );
};

export default ContainerLog;

import { PlusCircleIcon } from '@heroicons/react/24/solid';
import React, { useEffect, useState } from 'react';
import Breadcrumbs from '../../components/Breadcrumbs';
import { getAllModelVersionControls } from '../../handlers/apiCallHandler.tsx';
import { useAuth } from '../../handlers/authHandler';
import ModelVersionCard from '../../components/ModelVersionCard';
import { Link } from 'react-router-dom';
import ModelVersionListSkeleton from './skeleton';

const ModelVersionControl = () => {
  const [modelVersions, setModelVersions] = useState([]);
  const [loading, setLoading] = useState(false);

  const auth = useAuth();

  useEffect(() => {
    setLoading(true);
    getAllModelVersionControls()
      .then((data) => {
        setModelVersions(data.data);
        setLoading(false);
      })
      .catch((err) => {
        if (err?.response?.status === 401) return auth.logout();
      });
  }, []);

  useEffect(() => {
    document.title = 'Digime model version control dashboard';
  }, []);

  return (
    <div className="relative">
      <Breadcrumbs />
      <Link to={`create`}>
        <div className="bg-orange-600 text-white p-2 text-center font-medium w-full rounded-md mt-2 hover:bg-orange-700 block sm:hidden shadow-sm">
          Create
        </div>
      </Link>
      <ModelVersionListSkeleton enabled={loading} />
      <div className="mt-3 grid grid-cols-1 sm:grid-cols-3 md:grid-cols-4 gap-2">
        <Link to={`create`}>
          <div className="h-full items-center justify-center border-2 border-slate-400 border-dashed rounded-md cursor-pointer min-h-28 hidden sm:flex">
            <PlusCircleIcon className="size-7 text-slate-400" />
          </div>
        </Link>
        {modelVersions.map((model) => (
          <Link key={model.id} to={`${model.id}`}>
            <ModelVersionCard model={model} />
          </Link>
        ))}
      </div>
    </div>
  );
};

export default ModelVersionControl;

import clsx from 'clsx';
import React, { forwardRef } from 'react';

const Input = forwardRef(function InternalInput(
  {
    title = 'Title',
    placeholder = 'Enter data',
    htmlFor = '',
    customClassName = '',
    type = 'text',
    onChange,
    disabled = false,
  },
  ref,
) {
  return (
    <div className={customClassName}>
      <label
        htmlFor={htmlFor}
        className="block mb-2 text-sm font-medium text-gray-900">
        {title}
      </label>
      <input
        ref={ref}
        id={htmlFor}
        className={clsx([
          'border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-orange-600 focus:border-orange-600 block w-full p-2.5',
          disabled ? 'bg-gray-300' : 'bg-gray-50',
        ])}
        placeholder={placeholder}
        required
        type={type}
        disabled={disabled}
        onChange={onChange}
      />
    </div>
  );
});

export default Input;

import React, { createContext, useEffect } from 'react';

import { autocompletionHandler } from '../handlers/autocompletionHandler';
import {
  getAllModelVersionControls,
  getAllNodes,
} from '../handlers/apiCallHandler.tsx';
import { useAuth } from '../handlers/authHandler';

const AutoCompletionContext = createContext(null);

export const AutoCompletionProvider = ({ children }) => {
  const auth = useAuth();

  useEffect(() => {
    getAllNodes()
      .then((data) => {
        data.forEach((node) => autocompletionHandler.pushNode(node));
        return getAllModelVersionControls();
      })
      .then((data) => {
        data['data']
          .map((el) => el.versions.map((version) => ({ ...el, version })))
          .flat()
          .forEach((model_version) =>
            autocompletionHandler.pushVersion(model_version),
          );
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          return auth.logout();
        }
      });
  }, []);

  return (
    <AutoCompletionContext.Provider value={{}}>
      {children}
    </AutoCompletionContext.Provider>
  );
};

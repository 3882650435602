export const BREADCRUMB_CATEGORIES = {
  MACHINE: 'MACHINE',
  API_KEY: 'API_KEY',
  PERMISSION: 'PERMISSION',
  MODEL_VERSION_CONTROL: 'MODEL_VERSION_CONTROL',
  PLAYGROUND: 'PLAYGROUND',
  SETTING: 'SETTING',
  USER_SETTING: 'USER_SETTING',
  PERMISSION_SETTING: 'PERMISSION_SETTING',
  DOWNLOAD_FILE: 'DOWNLOAD_FILE',
  CHART: 'CHART',
};

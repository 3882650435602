import { ArrowPathIcon, DocumentTextIcon } from '@heroicons/react/24/solid';
import React, { useCallback } from 'react';
import { getDistanceTime } from '../../handlers/datetimeHandler';
import { useDeployment } from '../../contexts/DeploymentContext';
import { ActionPolicy } from '../../constants/container.constant';
import { useParams } from 'react-router-dom';
import { interactContainer } from '../../handlers/apiCallHandler.tsx';
import { useAuth } from '../../handlers/authHandler';
import { useToast } from '../../contexts/ToastContext';
import Tooltip from '../../common/Tooltip';

const NodeCoreContainer = ({ container }) => {
  const { handleContainerId } = useDeployment();
  const auth = useAuth();

  const params = useParams();

  const { openToast } = useToast();

  const openContainerLog = useCallback(
    (containerId, errorEvent) => () =>
      handleContainerId(containerId, errorEvent),
    [],
  );

  const restartContainer = useCallback(
    (containerId) => () => {
      const body = {
        action: ActionPolicy.RESTART,
      };

      interactContainer(params['nodeId'], containerId, body)
        .then(() => {
          openToast('SUCCESS', 'Restart successfully');
        })
        .catch((err) => {
          if (err?.response?.status == 401) return auth.logout();
        });
    },
    [],
  );

  return (
    <div className="bg-white rounded-md shadow cursor-pointer border relative">
      <span className="text-xs p-1 bg-orange-200 text-orange-700 absolute rounded-sm right-0">
        {getDistanceTime(container.date)}
      </span>
      <div className="w-full p-3 border-b hover:bg-slate-50">
        <div className="flex items-center justify-between">
          <div className="text-md font-medium text-slate-700">
            <Tooltip message={container.name}>
              <div className="truncate w-36">{container.name}</div>
            </Tooltip>
          </div>
          <div className="text-xs text-slate-600">
            <Tooltip message={container.image}>
              <div className="truncate w-20">{container.image}</div>
            </Tooltip>
          </div>
        </div>
        <div className="text-xs text-slate-600">
          Gateway: {container.gateway}
        </div>
        <div className="text-xs text-slate-600">
          IP address: {`${container.ip}/${container.ip_prefix_len}`}
        </div>
      </div>
      <div className="grid grid-cols-2">
        <div
          className="px-7 py-3 border-r flex justify-center hover:bg-slate-50"
          onClick={restartContainer(container.id)}>
          <Tooltip message="Restart">
            <ArrowPathIcon className="size-4 text-slate-600" />
          </Tooltip>
        </div>
        <div
          className="px-7 py-3 flex justify-center hover:bg-slate-50"
          onClick={openContainerLog(container.name, null)}>
          <Tooltip message="See log">
            <DocumentTextIcon className="size-4 text-slate-600" />
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default NodeCoreContainer;

import React, { useCallback, useEffect, useRef, useState } from 'react';
import Breadcrumbs from '../../components/Breadcrumbs';
import Input from '../../common/Input';
import MultipleSelect from '../../common/MultipleSelect';
import { useToast } from '../../contexts/ToastContext';
import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/solid';
import {
  createUser,
  getAllUsers,
  updateUser,
} from '../../handlers/apiCallHandler.tsx';
import { useAuth } from '../../handlers/authHandler';
import { Spinner } from '../../assets/icons';
import Badge from '../../common/Badge';

const roles = [
  {
    id: 'MODERATOR',
    title: 'OPS team',
    value: 'MODERATOR',
  },
  {
    id: 'AI',
    title: 'AI team',
    value: 'AI',
  },
];

const INPUT_TYPE = {
  NAME: 'name',
  USERNAME: 'username',
  EMAIL: 'email',
  PASSWORD: 'password',
  RE_PASSWORD: 're_password',
};

const emailRegex = /^[\w-.]+@([\w-]+.)+[\w-]{2,4}$/g;

const UserSetting = () => {
  const nameRef = useRef(null);
  const usernameRef = useRef(null);
  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const rePasswordRef = useRef(null);
  const roleRef = useRef([]);

  const userEdittedRef = useRef(null);

  const [users, setUsers] = useState([]);

  const [isEdit, setIsEdit] = useState(false);

  const [loading, setLoading] = useState(false);

  const { openToast } = useToast();

  const auth = useAuth();

  useEffect(() => {
    getAllUsers()
      .then((data) => setUsers(data.data['data']))
      .catch((err) => {
        if (err?.response?.status === 401) return auth.logout();
      });
  }, []);

  const selectRole = useCallback((data) => {
    roleRef.current = [
      {
        id: data.id,
        title: data.title,
        value: data.value,
      },
    ];
  }, []);

  const deSelectRole = useCallback(() => {
    roleRef.current = [];
  }, []);

  const enterText = useCallback(
    (type) => (e) => {
      switch (type) {
        case INPUT_TYPE.NAME: {
          nameRef.current.value = e.target.value;
          return;
        }

        case INPUT_TYPE.USERNAME: {
          usernameRef.current.value = e.target.value;
          return;
        }

        case INPUT_TYPE.EMAIL: {
          passwordRef.current.value = e.target.value;
          return;
        }

        case INPUT_TYPE.PASSWORD: {
          passwordRef.current.value = e.target.value;
          return;
        }

        case INPUT_TYPE.RE_PASSWORD: {
          rePasswordRef.current.value = e.target.value;
          return;
        }

        default:
          return;
      }
    },
    [],
  );

  const validateInput = useCallback(() => {
    if (
      !nameRef.current.value ||
      !usernameRef.current.value ||
      !emailRef.current.value ||
      (!userEdittedRef.current && !passwordRef.current.value) ||
      (!userEdittedRef.current && !rePasswordRef.current.value) ||
      !roleRef.current.length
    ) {
      openToast('ERROR', 'Exists a empty field');
      return false;
    }

    if (!emailRef.current.value.match(emailRegex)) {
      openToast('ERROR', 'Invalid email');
      return false;
    }

    if (emailRef.current.value.length > 40) {
      openToast('ERROR', 'Email is too long');
      return false;
    }

    if (passwordRef.current.value !== rePasswordRef.current.value) {
      openToast('ERROR', 'Password does not match');
      return false;
    }

    return true;
  }, []);

  const submitOrUpdate = useCallback(() => {
    const result = validateInput();

    if (!result) return;

    setLoading(true);

    if (userEdittedRef.current) {
      updateUser(userEdittedRef.current, {
        full_name: nameRef.current.value,
        email: emailRef.current.value,
        role: roleRef.current[0].value,
        password: passwordRef.current.value || null,
        re_password: rePasswordRef.current.value || null,
      })
        .then(() => {
          setUsers((users) => {
            const newUsers = users.map((user) => {
              if (user.id === userEdittedRef.current) {
                console.log(nameRef.current.value);
                return {
                  ...user,
                  full_name: nameRef.current.value,
                  password: passwordRef.current.password,
                };
              }

              return user;
            });

            return newUsers;
          });
        })
        .catch((err) => {
          if (err?.response?.status == 401) return auth.logout();

          openToast('ERROR', err?.response?.status);
        });
      return;
    }

    createUser({
      full_name: nameRef.current.value,
      username: usernameRef.current.value,
      email: emailRef.current.value,
      password: passwordRef.current.value,
      re_password: rePasswordRef.current.value,
      role: roleRef.current[0].value,
    })
      .then((data) => {
        setUsers((users) =>
          users.concat({
            id: data['data']['id'],
            full_name: nameRef.current.value,
            username: usernameRef.current.value,
            email: emailRef.current.value,
            role: roleRef.current[0].value,
          }),
        );
      })
      .catch((err) => {
        if (err?.response?.status == 401) return auth.logout();

        openToast('ERROR', err?.response?.data.message);
      });
  }, []);

  const resetForm = useCallback(() => {
    nameRef.current.value = '';
    usernameRef.current.value = '';
    emailRef.current.value = '';
    passwordRef.current.value = '';
    rePasswordRef.current.value = '';
    roleRef.current = [];
  }, []);

  useEffect(() => {
    userEdittedRef.current = null;
    resetForm();
    setIsEdit(false);

    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, [users]);

  const removeUser = useCallback(
    (id) => () => {
      setUsers((users) => users.filter((usr) => usr.id !== id));
    },
    [],
  );

  const editUser = useCallback(
    (user) => () => {
      userEdittedRef.current = user.id;
      nameRef.current.value = user.full_name || '';
      usernameRef.current.value = user.username;
      emailRef.current.value = user.email;
      passwordRef.current.value = '';
      rePasswordRef.current.value = '';
      roleRef.current = [
        {
          id: user.role.toUpperCase(),
          title: user.role,
          value: user.role.toUpperCase(),
        },
      ];
      setIsEdit(user.id);
    },
    [],
  );

  return (
    <>
      <Breadcrumbs />
      <div className="p-3 bg-white mt-3 rounded-md shadow grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2">
        <Input
          ref={nameRef}
          title="Name"
          placeholder="Enter user name"
          customClassName="mb-3 sm:mb-none"
          onChange={enterText('name')}
        />
        <Input
          ref={usernameRef}
          title="Username"
          placeholder="Enter username"
          customClassName="mb-3 sm:mb-none"
          disabled={isEdit}
        />
        <Input
          ref={emailRef}
          title="Email"
          placeholder="Enter email"
          customClassName="mb-3 sm:mb-none"
          disabled={isEdit}
        />
        <Input
          ref={passwordRef}
          title="Password"
          placeholder="Enter password"
          type="password"
          customClassName="mb-3 sm:mb-none"
        />
        <Input
          ref={rePasswordRef}
          title="Re password"
          placeholder="Enter password again"
          type="password"
          customClassName="mb-3 sm:mb-none"
        />
        <div className="mb-3 sm:mb-none">
          <label
            htmlFor="role"
            className="block mb-2 text-sm font-medium text-gray-900">
            Role
          </label>
          <MultipleSelect
            list={roles}
            selected={roleRef.current}
            isMutileSelect={false}
            onSelect={selectRole}
            onDeselect={deSelectRole}
            text="Select role"
          />
        </div>
        <div></div>
        <button
          disabled={loading}
          onClick={submitOrUpdate}
          className="p-2 bg-orange-600 hover:bg-orange-700 rounded-md font-medium cursor-pointer">
          <div className="flex items-center justify-center">
            {loading && (
              <div className="me-2.5">
                <Spinner />
              </div>
            )}
            <div className="text-white">{isEdit ? 'Update' : 'Create'}</div>
          </div>
        </button>
        <div></div>
      </div>
      <div className="bg-white rounded-md shadow mt-3 overflow-x-auto">
        <table className="w-full text-sm text-left rtl:text-right text-gray-5">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
              <th scope="col" className="px-6 py-3">
                User
              </th>
              <th scope="col" className="px-6 py-3">
                Username
              </th>
              <th scope="col" className="px-6 py-3">
                Email
              </th>
              <th scope="col" className="px-6 py-3">
                Role
              </th>
              <th scope="col" className="px-6 py-3">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <tr className="bg-white border-b">
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                  {user.full_name}
                </th>
                <td className="px-6 py-4">{user.username}</td>
                <td className="px-6 py-4">{user.email}</td>
                <td className="px-6 py-4">
                  <Badge text={user.role} />
                </td>
                <td className="px-6 py-4">
                  <div className="flex items-center">
                    <button
                      onClick={editUser(user)}
                      className="rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8 me-3">
                      <PencilSquareIcon className="size-5 text-blue-600" />
                    </button>
                    <button
                      onClick={removeUser(user.id)}
                      className="rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8">
                      <TrashIcon className="size-5 text-red-600" />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default UserSetting;

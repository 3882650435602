import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { wsConfig } from '../handlers/websocketHandler';
import { useAuth } from '../handlers/authHandler';
import { useLocation, matchPath } from 'react-router-dom';

const WSContext = createContext(null);

export const WSProvider = ({ children }) => {
  const [wsLoading, setWsLoading] = useState(true);
  const wsConfigRef = useRef(wsConfig);
  const commandNumRef = useRef(1);

  const location = useLocation();

  const { user: token } = useAuth();

  const getCommandNumber = useCallback(() => {
    return ++commandNumRef.current;
  }, []);

  const triggerGettingNodeReport = useCallback(() => {
    const matchResult = matchPath('/nodes/:nodeId', window.location.pathname);

    if (matchResult) {
      const commandNum = getCommandNumber();

      const gettingNodeReportMessage = {
        data_cmd: {
          type: 'NODE_DETAIL_REPORT',
          payload: {
            node_id: matchResult.params['nodeId'],
            container_id: matchResult.params['nodeId'],
            command_num: commandNum,
          },
        },
      };

      wsConfig.sendMessage(gettingNodeReportMessage);

      return commandNum;
    }
  }, [location]);

  const handleCompleteLoading = useCallback(() => {
    setWsLoading(false);
    // triggerGettingNodeReport();
  }, []);

  useEffect(() => {
    wsConfigRef.current.onConnect(token, handleCompleteLoading);

    return () => {
      wsConfigRef.current.dispose();
    };
  }, []);

  const mqttContextValue = useMemo(
    () => ({
      wsLoading,
      triggerGettingNodeReport,
    }),
    [],
  );

  return (
    <WSContext.Provider value={mqttContextValue}>{children}</WSContext.Provider>
  );
};

export const useWS = () => useContext(WSContext);

import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import LineChart from '../../components/LineChart';
import {
  getContainersByNodeId,
  getNodeById,
  getStatesByNodeId,
  removeAContainer,
} from '../../handlers/apiCallHandler.tsx';
import clsx from 'clsx';
import dayjs from 'dayjs';

import ShowContainer from '../../components/ShowContainer';
import NodeContainer from '../../components/NodeContainer';
import DeploymentEditor from '../../components/DeploymentEditor';
import { useDeployment } from '../../contexts/DeploymentContext';
import ContainerLogWrapper from '../../components/ContainerLog/wrapper';
import Breadcrumbs from '../../components/Breadcrumbs';
import { AutoCompletionProvider } from '../../contexts/AutoCompletionContext';
import NodeCoreContainer from '../../components/NodeCoreContainer';
import Badge from '../../common/Badge';
import { wsConfig } from '../../handlers/websocketHandler';
import { useWS } from '../../contexts/WebSocketContext';
import { useAuth } from '../../handlers/authHandler';

const NodeDetail = () => {
  const [nodeState, setNodeState] = useState({
    xaxis: [],
    series: [],
    electricUsageXaxis: [],
    electricUsageSeries: [],
  });

  const [node, setNode] = useState({});

  const [containers, setContainers] = useState([]);

  const params = useParams();
  const navigate = useNavigate();

  const auth = useAuth();

  const { handleOpenYamlEditor } = useDeployment();

  const { triggerGettingNodeReport } = useWS();

  const convertLineChartValue = useCallback(
    (data, key) =>
      data.map((item) => ({
        series: item[key],
        xaxis: dayjs(`${item['created_at']}`).utc('z').local().unix() * 1000,
      })),
    [],
  );

  // eslint-disable-next-line
  const handleNodeState = useCallback((data) => {
    const temperatureLineChart = convertLineChartValue(
      data.data,
      'card_temperature',
    );

    const wattageLineChart = convertLineChartValue(
      data.data,
      'card_wattage_usage',
    );

    setNodeState({
      xaxis: temperatureLineChart.map((item) => item['xaxis']),
      series: temperatureLineChart.map((item) => item['series']),
      electricUsageXaxis: wattageLineChart.map((item) => item['xaxis']),
      electricUsageSeries: wattageLineChart.map((item) => item['series']),
    });
  }, []);

  const deleteContainer = useCallback(
    (containerId, realContainerId) => () => {
      removeAContainer({
        containerId: realContainerId,
        nodeId: params['nodeId'],
      })
        .then(() => {
          setContainers((state) =>
            state.filter((container) => container.id !== containerId),
          );
        })
        .catch((error) => {
          if (error.response.status === 401) return auth.logout();
        });
    },
    [],
  );

  const findContainerId = useCallback(
    (events, eventId) =>
      events.reduce(
        (init, event) =>
          event.container_id === '' ? init : event.container_id,
        eventId,
      ),
    [],
  );

  const updateChartReport = useCallback((message) => {
    if (message.type !== 'NODE_DETAIL_REPORT') return;

    const rawData = [
      {
        card_temperature: message.reason.card_temperature,
        card_wattage_usage: message.reason.card_wattage_usage,
        created_at: message.reason.created_at,
      },
    ];

    const temperatureLineChart = convertLineChartValue(
      rawData,
      'card_temperature',
    );

    const wattageLineChart = convertLineChartValue(
      rawData,
      'card_wattage_usage',
    );

    setNodeState((state) => {
      let xaxis = state.xaxis;
      let series = state.series;
      let electricUsageXaxis = state.electricUsageXaxis;
      let electricUsageSeries = state.electricUsageSeries;

      if (!xaxis.find((item) => item === temperatureLineChart[0]['xaxis'])) {
        const convertedXaxis = xaxis.concat(
          temperatureLineChart.map((data) => data['xaxis']),
        );
        const convertedSeries = series.concat(
          temperatureLineChart.map((data) => data['series']),
        );

        const convertedElectricXaxis = electricUsageXaxis.concat(
          wattageLineChart.map((data) => data['xaxis']),
        );
        const convertedElectricSeries = electricUsageSeries.concat(
          wattageLineChart.map((data) => data['series']),
        );

        xaxis = convertedXaxis.slice(-100);
        series = convertedSeries.slice(-100);

        electricUsageXaxis = convertedElectricXaxis.slice(-100);
        electricUsageSeries = convertedElectricSeries.slice(-100);
      }

      return {
        xaxis: xaxis,
        series: series,
        electricUsageXaxis: electricUsageXaxis,
        electricUsageSeries: electricUsageSeries,
      };
    });
  }, []);

  useEffect(() => {
    if (!wsConfig?.getWs() || wsConfig?.getWs()?.readyState != 1) return;

    const commandNumber = triggerGettingNodeReport();

    return () => {
      console.log('Unmount ws');
      if (wsConfig.getWs().readyState != 1) return;

      const cancelGettingLogMessage = {
        data_cmd: {
          type: 'CANCEL',
          payload: {
            command_service: 'NODE_DETAIL_REPORT',
            container_id: params['nodeId'],
            command_num: commandNumber || 0,
          },
        },
      };

      wsConfig.sendMessage(cancelGettingLogMessage);
    };
  }, []);

  useEffect(() => {
    const subscription = wsConfig.getContainerLogSubj().subscribe({
      next: updateChartReport,
    });

    return () => subscription?.unsubscribe();
  }, []);

  useEffect(() => {
    getNodeById(params['nodeId'])
      .then((data) => {
        setNode(data);
        return getStatesByNodeId(params['nodeId']);
      })
      .then((data) => {
        handleNodeState(data);
        return getContainersByNodeId(params['nodeId']);
      })
      .then((data) => {
        setContainers(data);
      })
      .catch((err) => {
        if (err?.response?.status === 401) navigate('/');
      });
  }, []);

  return (
    <>
      <Breadcrumbs />
      <div className="grid grid-cols-8 gap-2 mt-4 relative h-auto">
        <div className="col-span-8 sm:col-span-6">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-1 sm:gap-3 mb-2">
            <div className="bg-white rounded-md shadow-sm p-2 text-center font-medium border border-orange-600 truncate">
              {node.node_name}
            </div>
            <div className="bg-white rounded-md shadow-sm p-2 text-center font-medium border border-orange-600 truncate">
              {node.card_name}
            </div>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-1 sm:gap-3">
            <div className="bg-white rounded-md shadow-sm py-2">
              <div className="text-md font-medium text-center">
                Card temperature
              </div>
              <LineChart xaxis={nodeState.xaxis} series={nodeState.series} />
            </div>
            <div className="bg-white rounded-md shadow-sm py-2">
              <div className="text-md font-medium text-center">
                Card electric usage
              </div>
              <LineChart
                xaxis={nodeState.electricUsageXaxis}
                series={nodeState.electricUsageSeries}
              />
            </div>
          </div>
          <div className="grid-cols-1 mt-3">
            <Badge
              text="Core container"
              customClassName="text-md font-normal"
            />
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-2 mt-2 mb-8">
              {node.core_containers?.map((container) => (
                <NodeCoreContainer container={container} />
              ))}
            </div>
          </div>
        </div>
        <div className="hidden sm:block sm:col-span-2 sm:space-2 gap-2 h-[45rem]">
          <div
            onClick={handleOpenYamlEditor}
            className="bg-orange-600 text-white text-sm rounded-sm sm:rounded-md p-3 text-center cursor-pointer hover:bg-orange-700 shadow-md mb-2">
            Deploy container
          </div>
          <div
            className={clsx([
              'grid grid-cols-1 gap-2 relative',
              containers.length > 4 && 'h-full overflow-y-scroll',
            ])}>
            {containers.map((container) => (
              <NodeContainer
                key={container.id}
                container={container}
                deleteContainer={deleteContainer(
                  container.id,
                  findContainerId(container.events, container.event_id),
                )}
                findContainerId={findContainerId}
              />
            ))}
          </div>
        </div>
      </div>
      <ShowContainer
        containers={containers}
        deleteContainer={deleteContainer}
        findContainerId={findContainerId}
      />
      <div>
        <AutoCompletionProvider>
          <div className="hidden sm:block">
            <DeploymentEditor />
          </div>
        </AutoCompletionProvider>
        <ContainerLogWrapper />
      </div>
    </>
  );
};

export default NodeDetail;

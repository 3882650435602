import React, { useCallback, useEffect, useRef, useState } from 'react';
import Breadcrumbs from '../../components/Breadcrumbs';
import { PencilSquareIcon, CheckIcon } from '@heroicons/react/24/solid';
import MultipleSelect from '../../common/MultipleSelect';
import {
  getAllRoleGroups,
  getAllRolePermissions,
  updateRolePermissions,
} from '../../handlers/apiCallHandler.tsx';
import { useAuth } from '../../handlers/authHandler';
import { useToast } from '../../contexts/ToastContext.js';
import Badge from '../../common/Badge';

const PermissionSetting = () => {
  const [edittedRole, setEdittedRole] = useState('');
  const [roleGroups, setRoleGroup] = useState({
    total: 0,
    offset: 0,
    limit: 20,
    data: [],
  });

  const rolePermissionRef = useRef({
    total: 0,
    offset: 0,
    limit: 20,
    data: [],
  });

  const selectedPermissionsRef = useRef([]);

  const auth = useAuth();

  const { openToast } = useToast();

  useEffect(() => {
    getAllRoleGroups()
      .then((data) => {
        setRoleGroup({
          total: data.total,
          offset: data.offset,
          limit: data.limit,
          data: data.data,
        });
        return getAllRolePermissions();
      })
      .then((data) => {
        rolePermissionRef.current.total = data.total;
        rolePermissionRef.current.offset = data.offset;
        rolePermissionRef.current.limit = data.limit;
        rolePermissionRef.current.data = data.data.map((item) => ({
          id: item.id,
          title: item.title,
          value: item.permission,
        }));
      })
      .catch((err) => {
        if (err?.response?.status === 401) return auth.logout();
      });
  }, []);

  const editPermission = useCallback(
    (role, permissions) => () => {
      setEdittedRole(role);
      selectedPermissionsRef.current = permissions;
    },
    [],
  );

  const applyPermission = useCallback(
    (role) => () => {
      updateRolePermissions({
        role,
        permissions: selectedPermissionsRef.current,
      })
        .then(() => {
          openToast('SUCCESS', 'Update successfully');
          setEdittedRole('');
        })
        .catch((err) => {
          if (err?.response?.status === 401) return auth.logout();
        });
    },
    [],
  );

  const convertPermissionToString = useCallback((permissions) => {
    const length = permissions.length;

    if (length > 2)
      return `${permissions.slice(0, 2).join(', ')} +${length - 2}`;

    return permissions.join(', ');
  }, []);

  const convertPermissionToValidList = useCallback((permissions) => {
    return permissions.map((item) => ({ id: item, title: item, value: item }));
  }, []);

  const handleSelectPermission = useCallback((value) => {
    selectedPermissionsRef.current.push(value.value);
  });

  const handleDeselectPermission = useCallback((value) => {
    selectedPermissionsRef.current = selectedPermissionsRef.current.filter(
      (perm) => perm !== value.value,
    );
  });

  return (
    <>
      <Breadcrumbs />
      <div className="bg-white rounded-md shadow mt-3 overflow-x-auto">
        <table className="w-full text-sm text-left rtl:text-right text-gray-5">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
              <th scope="col" className="px-6 py-3">
                No
              </th>
              <th scope="col" className="px-6 py-3">
                Role
              </th>
              <th scope="col" className="px-6 py-3">
                Permissions
              </th>
              <th scope="col" className="px-6 py-3">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {roleGroups.data.map((item, index) => (
              <tr key={item.id}>
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                  {index + 1}
                </th>
                <td className="px-6 py-4">
                  <Badge text={item.role} />
                </td>
                <td className="px-6 py-4 w-72">
                  {edittedRole === item.role ? (
                    <MultipleSelect
                      text="Select permission"
                      list={rolePermissionRef.current.data}
                      selected={convertPermissionToValidList(item.permissions)}
                      onSelect={handleSelectPermission}
                      onDeselect={handleDeselectPermission}
                    />
                  ) : (
                    <div className="text-slate-500">
                      {convertPermissionToString(item.permissions)}
                    </div>
                  )}
                </td>
                <td className="px-6 py-4">
                  <div className="flex items-center">
                    <button className="rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8 me-3">
                      {edittedRole === item.role ? (
                        <CheckIcon
                          onClick={applyPermission(item.role)}
                          className="size-5 text-green-500"
                        />
                      ) : (
                        <PencilSquareIcon
                          onClick={editPermission(item.role, item.permissions)}
                          className="size-5 text-blue-600"
                        />
                      )}
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default PermissionSetting;

import React, { createContext, useCallback, useContext, useRef } from 'react';

import { useLocalStorage } from '../hooks/useLocalStorage';
import { useNavigate } from 'react-router-dom';
import Queue from '../helpers/Queue';
import { getProfile } from './apiCallHandler.tsx';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useLocalStorage('digime_access_token');
  const unauthQueueRef = useRef(new Queue());

  const navigate = useNavigate();

  const login = (user, token, expired_time) => {
    getProfile(token)
      .then((data) => {
        setUser({
          username: data.data['username'],
          token: token,
          full_name: data.data['full_name'],
          expired_time: expired_time,
          role: data.data['role'],
        });
      })
      .catch((e) => console.log(e));
  };

  const clearToken = useCallback(() => {
    localStorage.removeItem('digime_access_token');
    setUser(null);
    navigate('/login');
  }, []);

  const logout = useCallback(() => {
    unauthQueueRef.current.enqueue({ data: 'unauthorized' });
    if (unauthQueueRef.current.size === 1) clearToken();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        login,
        logout,
        unauthorizedQueue: unauthQueueRef.current,
      }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};

export const parseJwt = (token) => {
  if (!token) return 0;

  let base64Url = token.split('.')[1];
  let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  let jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(''),
  );

  return JSON.parse(jsonPayload);
};

import React, { useCallback, useEffect, useState } from 'react';
import {
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
} from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/24/solid';
import clsx from 'clsx';

const MultipleSelect = ({
  list = [],
  selected = [],
  text,
  onSelect,
  onDeselect,
  isMutileSelect = true,
}) => {
  const [selectedPersons, setSelectedPersons] = useState(selected);

  const isSelected = useCallback(
    (value) => {
      return selectedPersons.find((el) => el.value === value.value)
        ? true
        : false;
    },
    [selectedPersons],
  );

  useEffect(() => {
    setSelectedPersons(selected);
  }, [selected]);

  const handleSelect = useCallback(
    (value) => {
      if (!isSelected(value)) {
        const selectedPersonsUpdated = [
          ...selectedPersons,
          list.find((el) => el.value === value.value),
        ];
        setSelectedPersons(selectedPersonsUpdated);
        onSelect(value);
      } else {
        handleDeselect(value);
        onDeselect(value);
      }
    },
    [list, selectedPersons],
  );

  const handleSingleSelect = useCallback(
    (value) => {
      if (!isSelected(value)) {
        const selectedPersonsUpdated = [
          list.find((el) => el.value === value.value),
        ];
        setSelectedPersons(selectedPersonsUpdated);
        onSelect(value);
      } else {
        handleDeselect(value);
        onDeselect(value);
      }
    },
    [list, selectedPersons],
  );

  const handleOnChangeSelect = useCallback(
    (value) => {
      if (isMutileSelect) return handleSelect(value);

      return handleSingleSelect(value);
    },
    [list, selectedPersons],
  );

  const handleDeselect = useCallback(
    (value) => {
      const selectedPersonsUpdated = selectedPersons.filter(
        (el) => el.value !== value.value,
      );
      setSelectedPersons(selectedPersonsUpdated);
    },
    [selectedPersons],
  );

  return (
    <div className="flex items-center justify-center w-full">
      <div className="w-full">
        <Listbox
          id="permission-button-select"
          as="div"
          className="space-y-1"
          value={selectedPersons}
          onChange={handleOnChangeSelect}>
          {() => (
            <>
              <div className="relative">
                <div className="inline-block w-full rounded-md shadow-sm">
                  <ListboxButton className="bg-gray-50 w-full cursor-default relative w-full rounded-md border border-gray-300 pl-3 pr-10 py-2.5 text-left focus:outline-none focus:shadow-outline-orange focus:border-orange-600 transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                    <span className="block truncate text-sm sm:text-md">
                      {selectedPersons.length < 1
                        ? text
                        : `${text} (${selectedPersons.length})`}
                    </span>
                    <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                      <ChevronDownIcon className="size-3" />
                    </span>
                  </ListboxButton>
                </div>

                <ListboxOptions
                  anchor="bottom"
                  transition
                  className={clsx(
                    'w-[var(--button-width)] rounded-md border border-white bg-white p-1 [--anchor-gap:var(--spacing-1)] focus:outline-none shadow-md',
                    'transition duration-100 ease-in data-[leave]:data-[closed]:opacity-0 z-50',
                  )}>
                  {list.map((item) => {
                    const selected = isSelected(item);
                    return (
                      <ListboxOption key={item.id} value={item}>
                        {({ active }) => (
                          <div
                            className={`${
                              active
                                ? 'text-white bg-orange-600'
                                : 'text-gray-900'
                            } cursor-default select-none relative py-2 pl-8 pr-4`}>
                            <span
                              className={`${
                                selected ? 'font-semibold' : 'font-normal'
                              } block truncate`}>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: item.title,
                                }}></div>
                            </span>
                            {selected && (
                              <span
                                className={`${
                                  active ? 'text-white' : 'text-blue-600'
                                } absolute inset-y-0 left-0 flex items-center pl-1.5`}>
                                <CheckIcon className="size-3 text-orange-600" />
                              </span>
                            )}
                          </div>
                        )}
                      </ListboxOption>
                    );
                  })}
                </ListboxOptions>
              </div>
            </>
          )}
        </Listbox>
      </div>
    </div>
  );
};

export default MultipleSelect;

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { PlusCircleIcon } from '@heroicons/react/24/solid';

import Breadcrumbs from '../../components/Breadcrumbs';
import {
  deletePermissionByIdentifier,
  getAllPermissions,
} from '../../handlers/apiCallHandler.tsx';
import { useAuth } from '../../handlers/authHandler';
import PermissionCard from '../../components/PermissionCard';
import PermissionFormTransition from '../../components/PermissionForm';
import { useToast } from '../../contexts/ToastContext';
import PermissionListSkeleton from './skeleton';

const Permission = () => {
  const [permissions, setPermissions] = useState([]);
  const [permission, setPermission] = useState({});
  const [open, setOpen] = useState(false);
  const permissionDetailTimeoutRef = useRef(undefined);
  const [loading, setLoading] = useState(false);

  const auth = useAuth();

  const { openToast } = useToast();

  const openPermissionDetail = useCallback(
    (permission) => () => {
      clearTimeout(permissionDetailTimeoutRef.current);
      setPermission(permission);
      setOpen(true);
    },
    [],
  );

  const openCreationForm = useCallback(() => setOpen(true), []);

  const appendPermissionAfterCreating = useCallback(
    (permissionCreation) => {
      const newPermissions = [permissionCreation].concat(permissions);
      setPermissions(newPermissions);
    },
    [permissions],
  );

  const closePermissionDetail = useCallback(() => {
    setOpen(false);
    const timeoutId = setTimeout(() => setPermission({}), 500);
    permissionDetailTimeoutRef.current = timeoutId;
  }, []);

  const deletePermission = useCallback(
    (permissionId) => () => {
      deletePermissionByIdentifier(permissionId)
        .then((data) => {
          console.log(data);
          setPermissions((state) =>
            state.filter((permission) => permission.id !== permissionId),
          );
          openToast('SUCCESS', 'Delete successfully');
        })
        .catch((err) => {
          if (err?.response?.status === 401) {
            auth.logout();
            return;
          }
        });
    },
    [],
  );

  useEffect(() => {
    document.title = 'Digime permission dashboard';
  }, []);

  useEffect(() => {
    setLoading(true);

    getAllPermissions()
      .then((data) => {
        setPermissions(data.data);
        setLoading(false);
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          auth.logout();
          return;
        }
      });
  }, []);

  return (
    <div>
      <PermissionFormTransition
        open={open}
        close={closePermissionDetail}
        permission={permission}
        appendPermission={appendPermissionAfterCreating}
      />
      <Breadcrumbs />
      <div className="relative">
        <PermissionListSkeleton enabled={loading} />
      </div>
      <div
        onClick={openCreationForm}
        className="bg-orange-600 text-white p-2 text-center font-medium w-full rounded-md mt-2 hover:bg-orange-700 block sm:hidden shadow-sm">
        Create
      </div>
      <div className="mt-3 grid grid-cols-1 sm:grid-cols-3 md:grid-cols-4 gap-2">
        <div
          onClick={openCreationForm}
          className="items-center justify-center border-2 py-2 border-slate-400 border-dashed rounded-md cursor-pointer min-h-28 hidden sm:flex">
          <PlusCircleIcon className="size-7 text-slate-400" />
        </div>
        {permissions.map((permission) => (
          <PermissionCard
            deletePermission={deletePermission(permission.id)}
            key={permission.id}
            openPermission={openPermissionDetail(permission)}
            permission={permission}
          />
        ))}
      </div>
    </div>
  );
};

export default Permission;

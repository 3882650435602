import { useState } from 'react';
import { getProfile } from '../handlers/apiCallHandler.tsx';

export const useLocalStorage = () => {
  const [user, setUser] = useState(async () => {
    try {
      const user = await getProfile();

      return {
        username: user.data['username'],
        full_name: user.data['full_name'],
        role: user.data['role'],
      };
    } catch {
      return null;
    }
  });

  return [user, setUser];
};

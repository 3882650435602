import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
// import { jwtDecode } from 'jwt-decode';
import { UserIcon, KeyIcon } from '@heroicons/react/24/solid';

import { useAuth } from '../../handlers/authHandler';
import { login } from '../../handlers/apiCallHandler.tsx';
import LoginLogo from '../../assets/images/logo-login.svg';

const Login = () => {
  const [errors, setErrors] = useState([]);

  const usernameRef = useRef(null);
  const passwordRef = useRef(null);

  const auth = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    auth.unauthorizedQueue.clear();
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleLogin = useCallback(async () => {
    const username = usernameRef.current.value;
    const password = passwordRef.current.value;

    const loginResult = await login(username, password);

    if (!loginResult) {
      setErrors(['Username or password is incorrectly']);
    } else {
      auth.login('Digime', loginResult.token, loginResult.expired_time);
      navigate('/');
    }
  }, []);

  if (auth.user) return <Navigate to="/" />;

  return (
    <div className="h-screen flex items-center justify-center bg-slate-200">
      <form
        className="w-96 bg-white rounded-md px-10 py-10 shadow-md"
        action="/login"
        method="POST">
        <div className="grid grid-cols-2 gap-4">
          <div className="border-r">
            <img src={LoginLogo} />
          </div>
          <div className="font-medium flex items-center">
            Machine management
          </div>
        </div>
        {errors.length !== 0 && (
          <div className="mt-3 bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-red-400 border border-red-400">
            {errors.map((error) => error)}
          </div>
        )}
        <div className="mb-5 mt-3">
          <label
            htmlFor="username"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Username
          </label>
          <div className="relative">
            <div className="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
              <UserIcon className="size-5" />
            </div>
            <input
              type="text"
              id="username"
              name="username"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5"
              placeholder="Enter username"
              ref={usernameRef}
              required
            />
          </div>
        </div>
        <div className="mb-5">
          <label
            htmlFor="password"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Password
          </label>
          <div className="relative">
            <div className="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
              <KeyIcon className="size-5" />
            </div>
            <input
              type="password"
              id="password"
              name="password"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5"
              placeholder="Enter password"
              ref={passwordRef}
              required
            />
          </div>
        </div>
        <button
          // onClick={handleLogin}
          type="submit"
          className="cursor-pointer text-white bg-orange-600 hover:bg-orange-700 focus:ring-4 focus:outline-none focus:ring-orange-300 font-medium rounded-md text-sm w-full px-5 py-2.5 text-center w-full">
          Login
        </button>
      </form>
    </div>
  );
};

export default Login;

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ArrowPathIcon } from '@heroicons/react/24/solid';

import Badge from '../../common/Badge';
import {
  createModelVersionControl,
  deleteModelVersionControlByIdentifier,
  updateModelVersionControlByIdentifier,
} from '../../handlers/apiCallHandler.tsx';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../handlers/authHandler';
import { useToast } from '../../contexts/ToastContext';
import clsx from 'clsx';

const ModelVersionForm = ({
  model = {
    id: '',
    title: '',
    description: '',
    image: '',
    versions: [],
    prediction_type: '',
  },
  isEdit = false,
}) => {
  const titleRef = useRef(null);
  const descriptionRef = useRef(null);
  const imageRef = useRef(null);
  const versionRef = useRef(null);
  const predictionTypeRef = useRef(null);

  const [imageVersions, setImageVersions] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const params = useParams();

  const navigate = useNavigate();

  const auth = useAuth();

  const { openToast } = useToast();

  useEffect(() => {
    titleRef.current.value = model.title;
    descriptionRef.current.value = model.description;
    imageRef.current.value = model.image;
    predictionTypeRef.current.value = model.prediction_type;

    setImageVersions(model.versions);
  }, [model]);

  const saveModelVersionControl = useCallback(() => {
    setIsSubmitting(true);

    const body = {
      title: titleRef.current.value,
      description: descriptionRef.current.value,
      image: imageRef.current.value,
      versions: imageVersions,
      prediction_type: predictionTypeRef.current.value,
    };

    if (isEdit) {
      updateModelVersionControlByIdentifier(params['modelVersionId'], body)
        .then(() => setTimeout(() => setIsSubmitting(false), 500))
        .catch((err) => {
          if (err?.response?.status === 401) return auth.logout();

          setIsSubmitting(false);

          openToast('ERROR', err.toString());
        });

      return;
    }

    createModelVersionControl(body)
      .then(() =>
        setTimeout(() => {
          setIsSubmitting(false);
          navigate('/model-version-controls');
        }, 500),
      )
      .catch((err) => {
        if (err?.response?.status === 401) return auth.logout();

        setIsSubmitting(false);

        openToast('ERROR', err.toString());
      });
  }, [imageVersions, isEdit]);

  const deleteModelVersion = useCallback(
    (modelVersionId) => () => {
      deleteModelVersionControlByIdentifier(modelVersionId).then(() =>
        setTimeout(() => {
          navigate('/model-version-controls');
        }, 500),
      );
    },
    [],
  );

  const appendVersion = useCallback(
    (event) => {
      if (event.keyCode !== 13) return;

      setImageVersions(imageVersions.concat(versionRef.current.value));
      versionRef.current.value = '';
    },
    [imageVersions],
  );

  const removeVersion = useCallback(
    (version) => () => {
      setImageVersions((state) => state.filter((item) => item !== version));
    },
    [],
  );

  return (
    <div className="mt-3">
      <div className="bg-white p-3 rounded-md shadow-sm grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-3">
        <div>
          <label
            htmlFor="title"
            className="block mb-2 text-sm font-medium text-gray-900">
            Title
          </label>
          <input
            ref={titleRef}
            id="title"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-orange-600 focus:border-orange-600 block w-full p-2.5"
            placeholder="Enter title"
            required
          />
        </div>
        <div>
          <label
            htmlFor="description"
            className="block mb-2 text-sm font-medium text-gray-900">
            Description
          </label>
          <input
            ref={descriptionRef}
            id="description"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-orange-600 focus:border-orange-600 block w-full p-2.5"
            placeholder="Enter description"
            required
          />
        </div>
        <div>
          <label
            htmlFor="image"
            className="block mb-2 text-sm font-medium text-gray-900">
            Image name
          </label>
          <input
            ref={imageRef}
            id="image"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-orange-600 focus:border-orange-600 block w-full p-2.5"
            placeholder="Enter description"
            required
          />
        </div>
        <div>
          <label
            htmlFor="versions"
            className="block mb-2 text-sm font-medium text-gray-900">
            Versions
          </label>
          <div className="flex items-center p-2.5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md overflow-x-flow">
            <input
              onKeyDown={appendVersion}
              ref={versionRef}
              id="versions"
              className="focus:border-none focus:outline-none bg-gray-50 w-32"
              placeholder="Enter versions. Ex: 2.0.1"
            />
            <div className="overflow-x-scroll flex items-center w-full">
              {imageVersions.map((image, index) => (
                <Badge
                  key={index}
                  onClick={removeVersion(image)}
                  isTruncate={false}
                  text={image}
                />
              ))}
            </div>
          </div>
        </div>
        <div>
          <label
            htmlFor="prediction-type"
            className="block mb-2 text-sm font-medium text-gray-900">
            Prediction type
          </label>
          <input
            ref={predictionTypeRef}
            id="prediction-type"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-orange-600 focus:border-orange-600 block w-full p-2.5"
            placeholder="Example: OBJECT_DETECTION_PREDICTION"
            required
          />
        </div>
        <div className="hidden sm:block"></div>
        <div className="hidden sm:block"></div>
        <div>
          {isEdit ? (
            <div className="flex items-center justify-around">
              <div
                onClick={saveModelVersionControl}
                className="flex items-center justify-center py-2 px-3 w-28 sm:w-40 bg-orange-600 text-center text-white rounded-md font-medium hover:bg-orange-700 cursor-pointer">
                {isSubmitting && (
                  <ArrowPathIcon className="size-4 animate-spin" />
                )}
                <div className={clsx([isSubmitting && 'ml-2'])}>Save</div>
              </div>
              <div
                onClick={deleteModelVersion(model.id)}
                className="border border-orange-600 py-2 px-3 rounded-md w-28 sm:w-40 cursor-pointer text-center text-orange-600 hover:bg-slate-100">
                <div>Delete</div>
              </div>
            </div>
          ) : (
            <div
              onClick={saveModelVersionControl}
              className="flex items-center justify-center p-2 w-full bg-orange-600 text-center text-white rounded-md font-medium hover:bg-orange-700 cursor-pointer">
              {isSubmitting && (
                <ArrowPathIcon className="size-4 animate-spin" />
              )}
              <div className="ml-2">Create</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ModelVersionForm;

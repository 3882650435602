export enum Role {
  ADMIN = 'ADMIN',
  MODERATOR = 'MODERATOR',
  AI = 'AI',
}

export enum OperatingSystem {
  UBUNTU = 'ubuntu',
  WINDOWS = 'windows',
}

export enum GPUProvider {
  NVIDIA = 'nvidia',
  AMD = 'amd'
}

import React, { useEffect, useState } from 'react';
import {
  CpuChipIcon,
  FingerPrintIcon,
  KeyIcon,
  RectangleStackIcon,
  CodeBracketSquareIcon,
  Cog6ToothIcon,
  UserGroupIcon,
  AdjustmentsHorizontalIcon,
  FolderArrowDownIcon,
} from '@heroicons/react/24/solid';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { useBreadcrumbs } from '../../contexts/BreadcrumbsContext';
import { useAuth } from '../../handlers/authHandler';
import { Role } from '../../types/common';

const Sidebar = () => {
  const [profile, setProfile] = useState({});
  const { breadcrumbs } = useBreadcrumbs();

  const { user } = useAuth();

  useEffect(() => {
    if (user['role']) {
      setProfile(user);
      return;
    }
    user.then((data) => setProfile(data));
  }, []);

  if (
    profile &&
    profile['role'] === Role.ADMIN &&
    breadcrumbs.length &&
    breadcrumbs[0].path === '/dashboards'
  )
    return <ul className="space-y-2 font-medium sm:mt-14" id="sidebar"></ul>;

  if (
    profile &&
    profile['role'] === Role.ADMIN &&
    breadcrumbs.length &&
    breadcrumbs[0].path === '/settings'
  )
    return (
      <ul className="space-y-2 font-medium sm:mt-14" id="sidebar">
        <li
          className={clsx([
            breadcrumbs.length === 1 && breadcrumbs[0].path === '/settings'
              ? 'bg-slate-100 rounded-md'
              : '',
          ])}>
          <Link
            to="/settings"
            className="flex items-center p-2 text-gray-900 rounded-lg group">
            <Cog6ToothIcon className="size-6" />
            <span className="ms-3">General setting</span>
          </Link>
        </li>
        <li
          className={clsx([
            breadcrumbs.length &&
            breadcrumbs.find((item) => item['path'] === '/settings/users')
              ? 'bg-slate-100 rounded-md'
              : '',
          ])}>
          <Link
            to="/settings/users"
            className="flex items-center p-2 text-gray-900 rounded-lg group">
            <UserGroupIcon className="size-6" />
            <span className="ms-3">Users</span>
          </Link>
        </li>
        <li
          className={clsx([
            breadcrumbs.length &&
            breadcrumbs.find((item) => item['path'] === '/settings/permissions')
              ? 'bg-slate-100 rounded-md'
              : '',
          ])}>
          <Link
            to="/settings/permissions"
            className="flex items-center p-2 text-gray-900 rounded-lg group">
            <AdjustmentsHorizontalIcon className="size-6" />
            <span className="ms-3">Permissions</span>
          </Link>
        </li>
      </ul>
    );

  if (breadcrumbs.length && profile && profile['role'] !== Role.MODERATOR)
    return (
      <ul className="space-y-2 font-medium sm:mt-14" id="sidebar">
        <li
          className={clsx([
            breadcrumbs.length &&
            (breadcrumbs[0].path === '/' || breadcrumbs[0].path === '/nodes')
              ? 'bg-slate-100 rounded-md'
              : '',
          ])}>
          <Link
            to="/"
            className="flex items-center p-2 text-gray-900 rounded-lg group">
            <CpuChipIcon className="size-6" />
            <span className="ms-3">Machines</span>
          </Link>
        </li>
        <li
          className={clsx([
            breadcrumbs.length && breadcrumbs[0].path === '/api-keys'
              ? 'bg-slate-100 rounded-md'
              : '',
          ])}>
          <Link
            to="/api-keys"
            className="flex items-center p-2 text-gray-900 rounded-lg group">
            <KeyIcon className="size-6" />
            <span className="ms-3">API keys</span>
          </Link>
        </li>
        <li
          className={clsx([
            breadcrumbs.length && breadcrumbs[0].path === '/permissions'
              ? 'bg-slate-100 rounded-md'
              : '',
          ])}>
          <Link
            to="/permissions"
            className="flex items-center p-2 text-gray-900 rounded-lg group">
            <FingerPrintIcon className="size-6" />
            <span className="ms-3">Permissions</span>
          </Link>
        </li>
        <li
          className={clsx([
            breadcrumbs.length &&
            breadcrumbs[0].path === '/model-version-controls'
              ? 'bg-slate-100 rounded-md'
              : '',
          ])}>
          <Link
            to="/model-version-controls"
            className="flex items-center p-2 text-gray-900 rounded-lg group">
            <RectangleStackIcon className="size-6" />
            <span className="ms-3">Model Version Control</span>
          </Link>
        </li>
        <li
          className={clsx([
            breadcrumbs.length && breadcrumbs[0].path === '/playground'
              ? 'bg-slate-100 rounded-md'
              : '',
          ])}>
          <Link
            to="/playground"
            className="flex items-center p-2 text-gray-900 rounded-lg group">
            <CodeBracketSquareIcon className="size-6" />
            <span className="ms-3">Playground</span>
          </Link>
        </li>
      </ul>
    );

  if (breadcrumbs.length && profile && profile['role'] === Role.MODERATOR) {
    return (
      <ul className="space-y-2 font-medium sm:mt-14" id="sidebar">
        <li
          className={clsx([
            breadcrumbs.length &&
            (breadcrumbs[0].path === '/' || breadcrumbs[0].path === '/nodes')
              ? 'bg-slate-100 rounded-md'
              : '',
          ])}>
          <Link
            to="/"
            className="flex items-center p-2 text-gray-900 rounded-lg group">
            <CpuChipIcon className="size-6" />
            <span className="ms-3">Machines</span>
          </Link>
        </li>
        <li
          className={clsx([
            breadcrumbs.length && breadcrumbs[0].path === '/download-files'
              ? 'bg-slate-100 rounded-md'
              : '',
          ])}>
          <Link
            to="/download-files"
            className="flex items-center p-2 text-gray-900 rounded-lg group">
            <FolderArrowDownIcon className="size-6" />
            <span className="ms-3">Download</span>
          </Link>
        </li>
      </ul>
    );
  }

  return '';
};

export default Sidebar;

import React from 'react';
import { Transition } from '@headlessui/react';
import clsx from 'clsx';

const skeletonItems = new Array(7).fill(0);

const ApiKeySkeleton = () => {
  return (
    <div className="bg-white rounded-md p-3">
      <div className="animate-pulse">
        <div className="w-56 h-4 bg-slate-200 rounded-full"></div>
        <div className="mt-3 w-20 h-3 bg-slate-200 rounded-full"></div>
        <div className="flex items-center mt-3">
          <div className="w-40 h-4 bg-slate-200 rounded-full me-2"></div>
          <div className="h-4 w-8 rounded-full bg-slate-200"></div>
        </div>
        <div className="mt-3 w-44 h-4 bg-slate-200 rounded-full"></div>
      </div>
    </div>
  );
};

const ApiKeyListSkeleton = ({ enabled = false }) => {
  return (
    <Transition show={enabled}>
      <div
        className={clsx([
          // Base styles
          'absolute top-0 left-0 z-10 sm:z-30 transition ease-in-out w-full h-screen bg-slate-100',
          // Shared closed styles
          'data-[closed]:opacity-0',
          // Entering styles
          'data-[enter]:duration-200',
          // Leaving styles
          'data-[leave]:duration-500',
        ])}>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-2 mt-8">
          {skeletonItems.map((item, index) => (
            <ApiKeySkeleton key={index} />
          ))}
        </div>
      </div>
    </Transition>
  );
};

export default ApiKeyListSkeleton;

import React from 'react';
import { Transition } from '@headlessui/react';
import clsx from 'clsx';

const skeletonItems = new Array(7).fill(0);

const NodeStateSkeleton = () => {
  return (
    <div className="bg-white rounded-md p-3">
      <div className="animate-pulse">
        <div className="flex">
          <div className="w-16 me-2.5 h-4 bg-slate-200 rounded-full"></div>
          <div className="w-20 h-4 bg-slate-200 rounded-full"></div>
        </div>
        <div className="mt-3 w-56 h-5 bg-slate-200 rounded-full"></div>
        <div className="mt-3 w-48 h-4 bg-slate-200 rounded-full"></div>
        <div className="mt-3 w-44 h-4 bg-slate-200 rounded-full"></div>
        <div className="mt-3 border-b"></div>
        <div className="mt-3 px-10 flex items-center justify-between">
          <div className="h-4 w-16 bg-slate-200 rounded-full"></div>
          <div className="h-4 w-16 bg-slate-200 rounded-full"></div>
        </div>
      </div>
    </div>
  );
};

const NodeListSkeleton = ({ enabled = false }) => {
  return (
    <Transition show={enabled}>
      <div
        className={clsx([
          // Base styles
          'absolute top-0 left-0 z-10 sm:z-30 transition ease-in-out w-full h-screen bg-slate-100',
          // Shared closed styles
          'data-[closed]:opacity-0',
          // Entering styles
          'data-[enter]:duration-200',
          // Leaving styles
          'data-[leave]:duration-500',
        ])}>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mt-8">
          {skeletonItems.map((item, index) => (
            <NodeStateSkeleton key={index} />
          ))}
        </div>
      </div>
    </Transition>
  );
};

export default NodeListSkeleton;

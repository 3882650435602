import React from 'react';
import Breadcrumbs from '../../components/Breadcrumbs';

const GeneralSetting = () => {
  return (
    <>
      <Breadcrumbs />
      <div className="text-md mt-3">General settings</div>
      <div className="text-sm text-slate-500 text-sm">
        It will be comming soon
      </div>
    </>
  );
};

export default GeneralSetting;

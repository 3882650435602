import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Breadcrumbs from '../../components/Breadcrumbs';
import ApiKeyForm from '../../components/ApiKeyForm';
import { getApiKeyById } from '../../handlers/apiCallHandler.tsx';
import { useAuth } from '../../handlers/authHandler';

const ApiKeyDetail = () => {
  const [apiKey, setApiKey] = useState({
    title: 'Hello World',
    description: '',
    permissions: [],
    tags: [],
    is_banned: false,
    api_key: '',
    whitelist_ml_model_version: [],
    webhook_config: null,
  });

  const params = useParams();

  const auth = useAuth();

  useEffect(() => {
    getApiKeyById(params['apiKeyId'])
      .then((data) => setApiKey(data))
      .catch((error) => {
        if (error.response.status === 401) {
          return auth.logout();
        }
      });
  }, []);

  return (
    <>
      <Breadcrumbs />
      <ApiKeyForm apiKey={apiKey} is_edit={true} />
    </>
  );
};

export default ApiKeyDetail;
